import {
  ApolloLink,
  HttpLink,
  ApolloClient,
  defaultDataIdFromObject,
  gql,
} from '@apollo/client'
import { ApolloCache, InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'

import {
  getToken,
  isLoggedIn,
  getName,
  isEmailVerified,
  isEducational,
  getEmail,
} from '../account/utils'

let uri = 'https://bolt.eui.io/prod/graphql'

let staticURI = 'https://bolt.eui.io/prod/static'

if (process.env.NODE_ENV === 'development') {
  uri = 'http://127.0.0.1:8080/graphql'
  staticURI = 'http://127.0.0.1:8080/static'
}

if (process.env.NODE_ENV === 'test') {
  uri = 'http://127.0.0.1:8080/graphql'
  staticURI = 'http://127.0.0.1:8080/static'
}

const isTestEnv = process.env.NODE_ENV === 'test'

console.log('Using environment', process.env.NODE_ENV)

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: getToken() || '',
    },
  })

  return forward(operation)
})

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'CardNode':
        return object.id as string // use `id` as the primary key
      default:
        return defaultDataIdFromObject(object) // fall back to default handling
    }
  },
})

const client = new ApolloClient({
  link: ApolloLink.from([
    authMiddleware,
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
          // Don't print these in the test environment
          if (!isTestEnv) {
            console.log(
              `[GraphQL error]: Message: ${message}`,
              `Location: ${locations}`,
              `Path: ${path}`,
            )
          }
        })
      if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
      uri,
      credentials: 'same-origin',
    }),
  ]),

  cache,
})

/**
 * Set the data
 */
client.writeQuery({
  query: gql`
    query GetLocalCacheData {
      isLoggedIn @client
      isEmailVerified @client
      chosenTier @client
      couponCode @client
      isEducational @client
      hasConfirmedPaymentMethod @client
      hasConfirmedPurchase @client
    }
  `,
  data: {
    isLoggedIn: false,
    isEmailVerified: false,
    chosenTier: null,
    couponCode: null,
    isEducational: false,
    hasConfirmedPaymentMethod: false,
    hasConfirmedPurchase: false,
  },
})

/**
 * Sync the apollo state immediately if we're on the client
 */

if (typeof window !== 'undefined') {
  client.writeQuery({
    query: gql`
      query GetLocalCacheData {
        isLoggedIn @client
        isEmailVerified @client
        chosenTier @client
        couponCode @client
        isEducational @client
        hasConfirmedPaymentMethod @client
        hasConfirmedPurchase @client
      }
    `,
    data: {
      isLoggedIn: isLoggedIn(),
      isEmailVerified: isEmailVerified(),
      chosenTier: null,
      couponCode: null,
      isEducational: isEducational(),
      hasConfirmedPaymentMethod: false,
      hasConfirmedPurchase: false,
    },
  })
}

export { client, uri, staticURI }
