import React, { useEffect } from 'react'

export function ProcessQueryParameters(props: { children: React.ReactNode }) {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const urlParams = new URLSearchParams(window.location.search)

    // Move any query parameters into local storage
    const parameters = ['hello_source']

    let modified = false

    for (const parameter of parameters) {
      const val = urlParams.get(parameter)
      if (val) {
        console.log(`moving ${parameter}: ${val} to local storage`)
        // Set the value in local storage
        window.localStorage.setItem(parameter, val)
        // Remove it from the urlParams
        urlParams.delete(parameter)
        // Set our flag
        modified = true
      }
    }

    // Replace the history if modified
    if (modified) {
      if (Array.from(urlParams.keys()).length === 0) {
        window.history.replaceState({}, '', `${window.location.pathname}`)
      } else {
        window.history.replaceState(
          {},
          '',
          `${window.location.pathname}?${urlParams}`,
        )
      }
    }
  }, [])

  return props.children
}
