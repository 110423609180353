import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { client } from './src/apollo'
import { ProcessQueryParameters } from './src/components/ProcessQueryParameters'

// Wrap everything in an ApolloProvider, and process any
export const wrapRootElement = ({ element }) => {
  return (
    <ProcessQueryParameters>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </ProcessQueryParameters>
  )
}

// Scroll to the hash target on load
let offsetY = 65

const getTargetOffset = hash => {
  const id = window.decodeURI(hash.replace(`#`, ``))
  if (id !== ``) {
    const element = document.getElementById(id)
    if (element) {
      return element.offsetTop - offsetY
    }
  }
  return null
}

export const onInitialClientRender = (_, pluginOptions) => {
  if (pluginOptions.offsetY) {
    offsetY = pluginOptions.offsetY
  }

  requestAnimationFrame(() => {
    const offset = getTargetOffset(window.location.hash)
    if (offset !== null) {
      window.scrollTo(0, offset)
    }
  })
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const offset = getTargetOffset(location.hash)
  return offset !== null ? [0, offset] : true
}
