import { client } from '../../apollo'

import { gql } from '@apollo/client'

// Things get compiled down in a node environment without window.
// Just return nothing if that's happening

const getWasMidPurchase = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const wasMidPurchase = window.localStorage.getItem('midPurchase')

  if (!wasMidPurchase || wasMidPurchase === 'null') {
    return false
  }

  return wasMidPurchase === 'true'
}

const setWasMidPurchase = (wasMidPurchase: boolean) => {
  if (wasMidPurchase === null) {
    console.trace()
    return
  }

  if (typeof window === 'undefined') {
    return
  }

  window.localStorage.setItem('midPurchase', wasMidPurchase ? 'true' : 'false')
}

const getToken = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const token = window.localStorage.getItem('token')

  if (!token || token === 'null') {
    return null
  }

  return token
}

const isTokenValid = () => {
  // check if token is expired
  const token = getToken()
  const claims = parseJWTClaims(token)

  if (!claims) {
    return false
  }

  if (claims.exp < new Date().getTime() / 1000) {
    // it has expired, delete the token from local storage
    // don't use the delete token method, that will cause an infinite loop
    return false
  }

  return true
}

const setToken = (token: string | null) => {
  if (token === null) {
    console.trace()
    return
  }

  if (typeof window === 'undefined') {
    return
  }

  // Set the token in local storage
  window.localStorage.setItem('token', token)

  // Set it in Apollo as well
  syncApolloWithToken()
}

const deleteToken = () => {
  if (typeof window === 'undefined') {
    return
  }

  // Set the token in local storage
  window.localStorage.removeItem('token')

  syncApolloWithToken()
}

const isLoggedIn = () => {
  return !!getToken()
}

function syncApolloWithToken() {
  client.writeQuery({
    query: gql`
      query GetLocalCacheData {
        isLoggedIn @client
        isEmailVerified @client
        isEducational @client
      }
    `,
    data: {
      isLoggedIn: isLoggedIn(),
      isEmailVerified: isEmailVerified(),
      isEducational: isEducational(),
    },
  })
}

function base64urlDecode(str: string) {
  return atob(str).toString()
}

function parseJWTClaims(token: string | null) {
  if (!token) {
    return null
  }

  const segments = token.split('.')

  if (segments.length !== 3) {
    throw new Error('Not enough or too many segments')
  }

  const payloadSeg = segments[1]

  const payload = JSON.parse(base64urlDecode(payloadSeg))

  return payload
}

function getName() {
  const token = getToken()
  const claims = parseJWTClaims(token)

  if (!claims) {
    return null
  }

  return claims.name
}

function isEmailVerified() {
  const token = getToken()
  const claims = parseJWTClaims(token)

  if (!claims) {
    return null
  }

  return claims.verified
}

function isEducational() {
  const token = getToken()
  const claims = parseJWTClaims(token)

  if (!claims) {
    return null
  }

  return claims.edu
}

function getEmail() {
  const token = getToken()
  const claims = parseJWTClaims(token)

  if (!claims) {
    return null
  }

  return claims.email
}

export {
  getName,
  isEmailVerified,
  isEducational,
  getEmail,
  getToken,
  setToken,
  deleteToken,
  isLoggedIn,
  isTokenValid,
  getWasMidPurchase,
  setWasMidPurchase,
  parseJWTClaims,
}
